import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogLandingPage from './components/BlogLandingPage';
import ArticlePage from './components/ArticlePage';

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<BlogLandingPage />} />
        <Route path="/articles/:articleId" element={<ArticlePage />} />
      </Routes>
    </Router>
  );
}

export default App;
