// src/components/BlogLandingPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, description, date, id, heroImage }) => (
  <div className="w-full max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-3">
    <div className="md:flex">
      <div className="md:shrink-0">
        <img className="h-48 w-full object-cover md:h-full md:w-48" src={heroImage} alt={title} />
      </div>
      <div className="p-8">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{date}</div>
        <h3 className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{title}</h3>
        <p className="mt-2 text-gray-500">{description}</p>
        <Link to={`/articles/${id}`} className="mt-4 inline-block px-4 py-2 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
          Read More
        </Link>
      </div>
    </div>
  </div>
);

const BlogLandingPage = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch('/data/articles.json')
      .then(response => response.json())
      .then(data => setArticles(data.articles));
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <section className="relative h-96 mb-12 overflow-hidden rounded-lg">
        <img
          src="https://fastly.picsum.photos/id/1/5000/3333.jpg?hmac=Asv2DU3rA_5D1xSe22xZK47WEAN0wjWeFOhzd13ujW4"
          alt="Self-Publishing Blog Hero"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white">
          <h1 className="text-5xl font-bold mb-4">Self-Publishing Success</h1>
          <p className="text-xl mb-8">Your guide to thriving in the world of independent publishing</p>
          <button className="px-6 py-3 bg-white text-black rounded-md text-lg font-semibold hover:bg-gray-200 transition-colors">
            Start Your Journey
          </button>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-center">Latest Publishing Tips</h2>
        <div className="flex flex-wrap justify-center gap-6">
          {articles.map((article) => (
            <BlogPost key={article.id} {...article} />
          ))}
        </div>
      </section>

      <section className="text-center bg-gray-100 py-12 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Join Our Writers' Community</h2>
        <p className="text-xl mb-6">Subscribe to our newsletter for the latest self-publishing tips and industry updates.</p>
        <button className="px-6 py-3 bg-indigo-600 text-white rounded-md text-lg font-semibold hover:bg-indigo-700 transition-colors">
          Subscribe Now
        </button>
      </section>
    </div>
  );
};

export default BlogLandingPage;