import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function ArticlePage() {
  const [article, setArticle] = useState(null);
  const { articleId } = useParams();

  useEffect(() => {
    fetch('/data/articles.json')
      .then(response => response.json())
      .then(data => {
        const foundArticle = data.articles.find(a => a.id === articleId);
        setArticle(foundArticle);
      });
  }, [articleId]);

  const AmazonProductReference = ({ product }) => (
    <div className="mt-12 bg-gray-100 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4">Recommended Resource</h3>
      <div className="flex items-center">
        <img 
          src={product.imageUrl} 
          alt={product.title}
          className="w-32 h-auto mr-6"
        />
        <div>
          <h4 className="text-lg font-semibold mb-2">{product.title}</h4>
          <p className="mb-2">{product.description}</p>
          <a 
            href={product.linkUrl} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-yellow-400 text-black px-4 py-2 rounded hover:bg-yellow-500 transition-colors"
          >
            View on Amazon
          </a>
        </div>
      </div>
    </div>
  );

  if (!article) {
    return <div>Loading...</div>;
  }

return (
  <div className="container mx-auto px-4 py-8">
      <div className="relative h-96 mb-8 overflow-hidden rounded-lg">
        <img
          src={article.heroImage}
          alt={article.title}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-8">
          <h1 className="text-4xl font-bold mb-2 text-white">{article.title}</h1>
          <p className="text-xl text-gray-300">{article.date}</p>
        </div>
      </div>    <div className="prose lg:prose-xl mx-auto">
      <h2 className="text-2xl font-bold mt-6 mb-4">{article.title}</h2>
      <p className="mb-4">{article.description}</p>
      <div dangerouslySetInnerHTML={{ __html: article.content }} />
    </div>
    {article.amazonProduct && <AmazonProductReference product={article.amazonProduct} />}
  </div>
);
}

export default ArticlePage;